import axios from 'axios';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import './css/searchIndex.css';

function App() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [pageContent, setPageContent] = useState(''); // State to store extracted content
  const [urlData, seturlData] = useState(''); // State to store extracted content


  // Fetch data based on the query
  const fetchData = async (query) => {
    try {
      // const response = await axios.get(
      //   `https://search.abs.gov.au/s/search.html?form=json&collection=abs-anzsco&scope=%2Fstatistics%2Fclassifications%2Fanzsco-australian-and-new-zealand-standard-classification-occupations%2F2022&num_ranks=200&sort=metaabsCode&query=absCode%3A%24%2B%2B%20%5BabsCode%3A%60*${query}*%60%20absTitle%3A%60*${query}*%60%20absSynonyms%3A%60*${query}*%60%5D`
      // );
      //new update
      const response = await axios.get(
        `https://search.abs.gov.au/s/search.html?form=json&collection=abs-anzsco&scope=%2Fstatistics%2Fclassifications%2Fosca-occupation-standard-classification-australia%2F2024-version-1-0&num_ranks=200&sort=metaabsCode&query=absCode%3A%24%2B%2B%20%5BabsCode%3A%60*${query}*%60%20absTitle%3A%60*${query}*%60%20absSynonyms%3A%60*${query}*%60%5D`
      );
      // Extract data from results
      const extractedResults = response.data[0].results.map((result) => {
        return { result };
      });
      setResults(extractedResults);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchContent = async (urlData) => {
    try {
      const response = await axios.get(urlData, { headers: { Accept: 'text/html' } });
      console.log('API Response:', response.data); // Log the full response

      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, 'text/html');
      const pageContentDiv = doc.getElementById('page-content');

      if (pageContentDiv) {
        setPageContent(pageContentDiv.innerHTML);
      } else {
        setPageContent('No content found in <div id="page-content">');
      }
    } catch (error) {
      console.error('Error fetching content:', error);
      setPageContent('Failed to load content');
    }
  };

  // Debounce the API call with a 500ms delay
  const debouncedFetchData = useCallback(
    debounce((value) => {
      if (value) {
        fetchData(value);
        setShowDropdown(true);  // Show the dropdown when there is a query
      } else {
        setResults([]);
        setShowDropdown(false);  // Hide the dropdown when the input is cleared
      }
    }, 500),  // 500ms debounce delay
    []
  );

  // Function to remove the base URL
  const removeBaseUrl = (url) => {
    return url.replace("https://www.abs.gov.au/", "");
  };

  useEffect(() => {
    if (query) {
      debouncedFetchData(query);
    }
    return () => {
      debouncedFetchData.cancel();  // Cleanup debounce on unmount
    };
  }, [query, debouncedFetchData]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);  // Only update the query state
    setSelectedValue(null);    // Clear selected value when typing
    seturlData('');
  };

  const handleSelect = (absCode, absTitle, result) => {
    setSelectedValue(result.result);
    const modifiedUrl = removeBaseUrl(result.result.urls?.liveUrl);

    seturlData("https://anzscoapi.eloom.com.au/api/"+ modifiedUrl);
    fetchContent("https://anzscoapi.eloom.com.au/api/"+ modifiedUrl);
    // seturlData("http://localhost:5210/api/statistics/classifications/anzsco-australian-and-new-zealand-standard-classification-occupations/2022/browse-classification/3/35/351/3514");
    // fetchContent("http://localhost:5210/api/statistics/classifications/anzsco-australian-and-new-zealand-standard-classification-occupations/2022/browse-classification/3/35/351/3514");
    setQuery('');            // Clear the search input
    setShowDropdown(false);   // Hide the dropdown after selecting an item
  };

  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 0.2
        }}
    />
);


  const SearchResultsView = () => (
    <div>
      <h1>
        OSCA - Occupation Standard Classification for Australia
      </h1>
      <h5><u>Reference period 2024, Version 1.0</u></h5>
      <h5>Released: 6/12/2024</h5>
      <ColoredLine color="black" />
      <h2>Search Occupations</h2>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search for occupations..."
        style={{ width: "300px", padding: "10px" }}
        autoFocus // Ensure input is focused
      />

      {showDropdown && results.length > 0 && (
        <ul
          style={{
            listStyleType: "none",
            paddingLeft: 0,
            border: "1px solid #ccc",
            maxHeight: "150px",
            overflowY: "auto",
            width: "300px",
            position: "absolute",
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          {results.map((result, index) => (
            <li
              key={index}
              onClick={() =>
                handleSelect(
                  result.result.absCode,
                  result.result.absTitle,
                  result
                )
              }
              style={{
                padding: "10px",
                cursor: "pointer",
                borderBottom: "1px solid #ccc",
              }}
            >
              {result.result.listMetadata?.absCode?.[0] || ""} -{" "}
              {result.result.listMetadata?.absTitle?.[0] || ""}
            </li>
          ))}
        </ul>
      )}

      {selectedValue && (
        <div>
          {/* <header>
            <h1>
              {selectedValue.listMetadata?.absCode?.[0] || ""}{" "}
              {selectedValue.listMetadata?.absTitle?.[0] || ""}
            </h1>
            <p>Code: {selectedValue.listMetadata?.absCode?.[0] || ""}</p>
            <p>{selectedValue.summary || ""}</p>
          </header> */}
          <div dangerouslySetInnerHTML={{ __html: pageContent }} />
          <a
            href={selectedValue.urls?.liveUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            View more on ABS website
          </a>

          {/* <section className="skill-level">
            <h2>Indicative Skill Level:</h2>
            <p>
              Most occupations in this unit group have a level of skill
              commensurate with the qualifications outlined below.
            </p>
          </section> */}
        </div>
      )}
    </div>
  );

  return (
    <div className="App">
      <div className="container">
        <nav className="sidebar">
          <h3>OSCA Search</h3>
          <ul></ul>
        </nav>
        <div className="content">
          <SearchResultsView />
        </div>
      </div>
    </div>
  );
}

export default App;
